<template>
<v-card class="rounded-lg" flat>
    <v-card-text class="pa-0">
        <v-container>
            <v-row class="pa-0">
                <v-col cols="12" class="pa-0">
                    <v-toolbar flat height="62" class="rounded-t-lg">
                        <v-list-item class="px-0">
                            <v-list-item-avatar class="mr-2 rounded-lg" color="secondary" tile width="8" height="30" min-width="8" />
                            <v-list-item-content>
                                <v-list-item-title class="headline mb-0">
                                    <v-toolbar-title class="pa-0 text-capitalize grey--text text--darken-2 trenos-title-toolbar">
                                        {{ModoEdit}}
                                    </v-toolbar-title>
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-menu v-model="turno_opciones" :close-on-content-click="true" :nudge-width="200" origin="right top" rounded="lg"  left transition="scale-transition" max-width="200" v-if="turno_edit.id">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on" :disabled="mod_btn_loading">
                                            <v-icon color="primary" size="20">mdi-cog-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item @click="TurnoEliminar()">
                                            <v-list-item-icon class="mr-3">
                                                <v-icon size="20">mdi-trash-can-outline</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Eliminar Turno</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-list-item-action>
                        </v-list-item>
                    </v-toolbar>
                </v-col>
            </v-row>
        </v-container>
    </v-card-text>
    <v-card class="px-0 pt-1" flat max-height="70vh" style="overflow: auto;">
        <v-container>
            <v-row>
                <v-col cols="12" md="5">
                    <v-card outlined class="pa-5 rounded-lg">
                        <v-row>
                            <v-col cols="12" class="py-0">
                                <v-alert dense type="info" color="primary" icon="mdi-information-outline" text transition="fade-transition" rounded="lg" :value="turno_edit.turno_modificado.status">
                                    Se generará un nuevo turno
                                </v-alert>
                            </v-col>
                            <v-col cols="4" class="py-2 pr-1">
                                <v-text-field label="Nombre" outlined dense :maxlength="9" autocomplete="off" type="text" v-mask="'XXXXXX'" hide-details v-model="turno_edit.turno" />
                            </v-col>
                            <v-col cols="8" class="py-2 pl-1">
                                <v-select :items="lista_tipo" label="Tipo Turno" outlined dense hide-details item-text="nombre" item-value="valor" v-model="turno_edit.tipo" append-icon="" />
                            </v-col>
                            <v-col cols="6" class="py-2 pr-1">
                                <v-select :items="lista_lugares.filter((x) => x.valor != 'eb/sa')" label="Entrada" outlined dense hide-details item-text="nombre" item-value="valor" v-model="turno_edit.entrada" clearable append-icon="" />
                            </v-col>
                            <v-col cols="6" class="py-2 pl-1">
                                <v-select :items="lista_lugares" label="Salida" outlined dense hide-details item-text="nombre" item-value="valor" v-model="turno_edit.salida" clearable append-icon="" />
                            </v-col>
                            <v-col cols="4" class="py-2 pr-1">
                                <v-text-field label="Inicio" outlined dense :maxlength="9" autocomplete="off" type="time" hide-details v-model="turno_edit.inicio" @change="DuracionTurno()" />
                            </v-col>
                            <v-col cols="4" class="py-2 px-1">
                                <v-text-field label="Termino" outlined dense :maxlength="9" autocomplete="off" type="time" hide-details v-model="turno_edit.termino" @change="DuracionTurno()" />
                            </v-col>
                            <v-col cols="4" class="py-2 pl-1">
                                <v-text-field label="Duracion" outlined dense :maxlength="9" autocomplete="off" type="time" hide-details v-model="turno_edit.duracion" />
                            </v-col>
                            <v-col cols="6" class="py-2 pr-1">
                                <v-menu v-model="dialog_fecha_inicio" :close-on-content-click="false" transition="scroll-x-transition" min-width="auto" right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field label="Fecha inicio" outlined dense :value="FormatFechaInicio" maxlength="12" autocomplete="off" v-bind="attrs" v-on="on" readonly hide-details />
                                    </template>
                                    <v-card class="rounded-lg" flat>
                                        <v-date-picker v-model="turno_edit.fecha_inicio" scrollable flat :show-current="true" no-title first-day-of-week="1" type="date" @change="dialog_fecha_inicio = false">
                                            <v-spacer />
                                            <v-btn text color="primary" @click="turno_edit.fecha_inicio = null; dialog_fecha_inicio = false;" :disabled="!turno_edit.fecha_inicio">Limpiar</v-btn>
                                            <v-btn text color="primary" @click="FechaHoy('inicio')">Hoy</v-btn>
                                        </v-date-picker>
                                    </v-card>
                                </v-menu>
                            </v-col>
                            <v-col cols="6" class="py-2 pl-1">
                                <v-menu v-model="dialog_fecha_termino" :close-on-content-click="false" transition="scroll-x-transition" min-width="auto" right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field label="Fecha termino" outlined dense :value="FormatFechaTermino" maxlength="12" autocomplete="off" v-bind="attrs" v-on="on" readonly hide-details />
                                    </template>
                                    <v-card class="rounded-lg" flat>
                                        <v-date-picker v-model="turno_edit.fecha_termino" scrollable flat :show-current="true" no-title first-day-of-week="1" type="date" :min="turno_edit.fecha_inicio ? turno_edit.fecha_inicio : null" @change="dialog_fecha_termino = false">
                                            <v-spacer />
                                            <v-btn text color="primary" @click="turno_edit.fecha_termino = null; dialog_fecha_termino = false;" :disabled="!turno_edit.fecha_termino">Limpiar</v-btn>
                                            <v-btn text color="primary" @click="FechaHoy('termino')">Hoy</v-btn>
                                        </v-date-picker>
                                    </v-card>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" class="py-2">
                                <v-text-field label="Nombre Descriptivo" outlined dense :maxlength="20" autocomplete="off" type="text" hide-details v-model="turno_edit.nombre" />
                            </v-col>
                            <v-col cols="12" class="py-2">
                                <v-text-field label="Observacion" outlined dense :maxlength="20" autocomplete="off" type="text" hide-details v-model="turno_edit.obs" />
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="12" md="7">
                    <v-row class="pa-0">
                        <v-col class="pa-0" cols="12">
                            <v-card flat class="d-flex justify-center">
                                <v-btn text color="primary" @click="AddServicio()">
                                    Añadir Servicio
                                </v-btn>
                                <v-btn text color="primary" @click="AddOtro()">
                                    Añadir Otro
                                </v-btn>
                                <v-btn text color="primary" icon @click="OrdenarEdit()" :disabled="!OrdenarBtn">
                                    <v-icon>mdi-update</v-icon>
                                </v-btn>
                            </v-card>
                        </v-col>
                        <v-col cols="12" class="py-8">
                            <v-row v-for="(detalle, index) in turno_edit.detalle" :key="index">
                                <v-col cols="3" class="pr-1 pt-0" v-if="turno_edit.detalle[index].hasOwnProperty('servicio')">
                                    <v-text-field outlined  label="Servicio" dense :maxlength="3" autocomplete="off" type="number" hide-details v-model="turno_edit.detalle[index].servicio" v-mask="'###'" />
                                </v-col>
                                <v-col cols="3" class="px-1 py-0" v-if="turno_edit.detalle[index].hasOwnProperty('servicio')">
                                    <v-select outlined :items="lista_recorridos" label="Recorrido" dense hide-details item-text="nombre" item-value="valor" append-icon="" v-model="turno_edit.detalle[index].dir" />
                                </v-col>
                                <v-col cols="6" class="pr-1 py-0" v-else>
                                    <v-text-field outlined label="Detalle" dense autocomplete="off" type="text" hide-details v-model="turno_edit.detalle[index].dir"/>
                                </v-col>
                                <v-col cols="2" class="px-1 pt-0">
                                    <v-text-field outlined label="Inicio" dense autocomplete="off" type="time" hide-details v-model="turno_edit.detalle[index].inicio" />
                                </v-col>
                                <v-col cols="2" class="pl-1 pr-0 pt-0">
                                    <v-text-field outlined label="Termino" dense autocomplete="off" type="time" hide-details v-model="turno_edit.detalle[index].termino" />
                                </v-col>
                                <v-col cols="2" class="pl-1 pt-0 d-flex justify-center ">
                                    <v-btn class="ma-0" text icon color="red" @click=" turno_edit.detalle.splice(index, 1); ">
                                        <v-icon>mdi-close-circle-outline</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
    <v-card-text class="px-0 py-4">
        <v-container>
            <v-row>
                <v-col cols="12" class="pa-0">
                    <v-card flat class="text-center">
                        <v-btn depressed color="primary" @click="AddTurno()" :disabled="!ModificarBtn" :loading="mod_btn_loading">{{EditBtn}}</v-btn>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-card-text>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="CerrarDialog">
            Cerrar
        </v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import {
    mapState,
    mapMutations
} from "vuex";
import dayjs from "dayjs";
export default {
    name: "TurnoEdit",
    props: ['turno_datos'],
    data() {
        return {
            dialog_fecha_inicio: false,
            dialog_fecha_termino: false,
            lista_lugares: [
                { nombre: "Puerto", valor: "pu" },
                { nombre: "Limache", valor: "li" },
                { nombre: "El Belloto", valor: "eb" },
                { nombre: "S. Aldea", valor: "sa" },
                { nombre: "EB/SA", valor: "eb/sa" },
            ],
            lista_tipo: [
                { nombre: "Full Time Mañana", valor: "fm" },
                { nombre: "Full Time Tarde", valor: "ft" },
                { nombre: "Part Time Mañana 120", valor: "lms" },
                { nombre: "Part Time Tarde 120", valor: "lts" },
                { nombre: "Part Time Mañana 80", valor: "lm" },
                { nombre: "Part Time Tarde 80", valor: "lt" },
                { nombre: "Sabado Mañana", valor: "sm" },
                { nombre: "Sabado Tarde", valor: "st" },
                { nombre: "Domingo Mañana", valor: "dm" },
                { nombre: "Domingo Tarde", valor: "dt" },
                { nombre: "Otro", valor: "na" }
            ],
            lista_recorridos: [
                { nombre: 'PU-LI', valor: 'pu-li' },
                { nombre: 'LI-PU', valor: 'li-pu' },
                { nombre: 'PU-SA', valor: 'pu-sa' },
                { nombre: 'SA-PU', valor: 'sa-pu' },
                { nombre: 'PU-EB', valor: 'pu-eb' },
                { nombre: 'EB-PU', valor: 'eb-pu' },
                { nombre: 'SA-MI', valor: 'sa-mi' },
                { nombre: 'PO-SA', valor: 'po-sa' },
                { nombre: 'LI-BA', valor: 'li-ba' }
            ],
            turno_edit: {
                id: null,
                turno: null,
                tipo: null,
                entrada: null,
                salida: null,
                inicio: null,
                termino: null,
                duracion: null,
                fecha_inicio: null,
                fecha_termino: null,
                nombre: null,
                detalle: [],
                turno_modificado: {
                    fecha_original: null,
                    status: false
                },
                obs: null
            },
            mod_btn_loading: false,
            del_btn_loading: false,
            turno_opciones: false

        }
    },
    computed: {
        ...mapState(['dialog', 'usuario', 'update']),
        FormatFechaInicio() { //Formatea la fecha de inicio al formato DD-MM-YYYY
            let fecha_inicio = null;
            if (this.turno_edit.fecha_inicio)
                fecha_inicio = dayjs(this.turno_edit.fecha_inicio).format("DD-MM-YYYY");
            return fecha_inicio;
        },
        FormatFechaTermino() { //Formatea la fecha de termino al formato DD-MM-YYYY
            let fecha_termino = null;
            if (this.turno_edit.fecha_termino)
                fecha_termino = dayjs(this.turno_edit.fecha_termino).format(
                    "DD-MM-YYYY"
                );
            return fecha_termino;
        },
        OrdenarBtn() { //Verifica que todos los horarios del detalle del turno esten correctas
            let enable = true
            if (this.turno_edit.detalle.length > 1) {
                for (let index = 0; index < this.turno_edit.detalle.length; index++) {
                    if (!(this.turno_edit.detalle[index].inicio && this.turno_edit.detalle[index].termino)) {
                        enable = false
                    } else {
                        if (this.turno_edit.detalle[index].inicio.length != 5 || this.turno_edit.detalle[index].termino.length != 5) {
                            enable = false
                        }
                    }
                }
            } else {
                enable = false
            }
            return enable
        },
        ModificarBtn() { //Habilita o desabilita el boton de modificar turno con base en los requisitos para modificar un turno
            let enabled = false
            if (this.turno_edit.turno && this.turno_edit.tipo && this.turno_edit.fecha_inicio) enabled = true
            return enabled
        },
        DialogEdit() { //Entrega el estado global de la variable del dialog turno_edit.
            return this.dialog.turno_edit
        },
        ModoEdit() { //Entrega el titulo del dialog (edicion o nuevo turno)
            let modo
            if (this.turno_datos) modo = 'Edicion de turno'
            else modo = 'Agregar nuevo turno'
            return modo
        },
        FechaInicio() {
            return this.turno_edit.fecha_inicio
        },
        EditBtn(){
            let nombre = 'Agregar'
            if(this.turno_edit.id) nombre = 'Editar'
            return nombre
        }
    },
    methods: {
        ...mapMutations(["ErrorRequest", 'CloseDialog', 'UpdateComponente']),
        FechaHoy(fecha_tipo) {
            if (fecha_tipo == "inicio") this.turno_edit.fecha_inicio = dayjs().format("YYYY-MM-DD")
            if (fecha_tipo == "termino") this.turno_edit.fecha_termino = dayjs().format("YYYY-MM-DD")
        },
        AddServicio() {
            let servicio = {
                servicio: null,
                dir: null,
                inicio: null,
                termino: null
            }
            this.turno_edit.detalle.push(servicio)
        },
        AddOtro() {
            let otro = {
                dir: null,
                inicio: null,
                termino: null
            }
            this.turno_edit.detalle.push(otro)
        },
        OrdenarEdit() {
            this.turno_edit.detalle.sort((a, b) => {
                let inicio = dayjs().hour(a.inicio.slice(0, -3)).minute(a.inicio.slice(3)).unix();
                let termino = dayjs().hour(b.inicio.slice(0, -3)).minute(b.inicio.slice(3)).unix();
                return inicio - termino
            });
        },
        DuracionTurno() {
            let duracion = null
            this.turno_edit.duracion = null
            if (this.turno_edit.inicio && this.turno_edit.termino) {
                if ((this.turno_edit.inicio.length == 5) && (this.turno_edit.termino.length == 5)) {
                    let inicio = dayjs().hour(this.turno_edit.inicio.slice(0, -3)).minute(this.turno_edit.inicio.slice(3)).unix();
                    let termino = dayjs().hour(this.turno_edit.termino.slice(0, -3)).minute(this.turno_edit.termino.slice(3)).unix();
                    if (termino > inicio) {
                        duracion = termino - inicio;
                        let duracion_horas = Math.floor(duracion / 3600);
                        if (duracion_horas < 10) duracion_horas = `0${duracion_horas}`;
                        duracion %= 3600;
                        let duracion_minutos = Math.floor(duracion / 60);
                        if (duracion_minutos < 10) duracion_minutos = `0${duracion_minutos}`;
                        duracion = `${duracion_horas}:${duracion_minutos}`;
                        this.turno_edit.duracion = duracion
                    }
                }
            }
        },
        async AddTurno() {
            this.mod_btn_loading = true
            let datos =  JSON.parse(JSON.stringify(this.turno_edit))
            datos.modificado = datos.turno_modificado.status
            this.$delete(datos, 'turno_modificado')
            await this.axios.post(`/buscar/turno/edit`, datos, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                    if (respuesta.data.mensaje.status == 0) {
                        this.UpdateComponente('turno')
                        this.UpdateComponente('buscar')
                        this.CerrarDialog()
                    }
                    this.mod_btn_loading = false
                    this.CerrarDialog()
                    this.turno_edit =  {
                        id: null,
                        turno: null,
                        tipo: null,
                        entrada: null,
                        salida: null,
                        inicio: null,
                        termino: null,
                        duracion: null,
                        fecha_inicio: null,
                        fecha_termino: null,
                        nombre: null,
                        detalle: [],
                        turno_modificado: {
                            fecha_original: null,
                            status: false
                        }
                    }
                }
            }).catch(err => {
                this.ErrorRequest(err)
                this.mod_btn_loading = false
            })
        },
        async TurnoEliminar() {
            let fecha_axios = dayjs(this.turno_edit.fecha_inicio).format('YYYYMMDD')
            this.mod_btn_loading = true
            this.del_btn_loading = true
            await this.axios.delete(`/buscar/turno/${this.turno_edit.id}/${fecha_axios}`, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                    if (respuesta.data.mensaje.status == 0) {
                        this.UpdateComponente('buscar')
                        this.CerrarDialog('edit')
                        this.CerrarDialog('turno')
                    }
                    this.mod_btn_loading = false
                    this.del_btn_loading = false
                }
            }).catch(err => {
                this.ErrorRequest(err)
                this.mod_btn_loading = false
                this.del_btn_loading = false
            })
        },
        CerrarDialog() {
            this.CloseDialog('turno_edit')
        },
        CargarDatos(datos) {
            datos = JSON.parse(JSON.stringify(datos))
            this.turno_edit.id = datos.id
            this.turno_edit.turno = datos.turno
            this.turno_edit.tipo = datos.tipo
            this.turno_edit.entrada = datos.entrada
            this.turno_edit.salida = datos.salida
            this.turno_edit.inicio = datos.inicio
            this.turno_edit.termino = datos.termino
            this.turno_edit.duracion = datos.duracion
            this.turno_edit.fecha_inicio = datos.fecha_ini
            this.turno_edit.fecha_termino = datos.fecha_ter
            this.turno_edit.nombre = datos.nombre
            this.turno_edit.detalle = datos.detalle
            this.turno_edit.obs = datos.obs
            this.turno_edit.turno_modificado.fecha_original = datos.fecha_ini
        }
    },
    mounted() {
        if (this.turno_datos) this.CargarDatos(this.turno_datos)
    },
    watch: {
        turno_datos: function (val) {
            if (val) {
                this.CargarDatos(val)
            }
        },
        FechaInicio: function (val) {
            if (val)
                if (val !== this.turno_edit.turno_modificado.fecha_original) this.turno_edit.turno_modificado.status = true
            else this.turno_edit.turno_modificado.status = false
        }
    }
};
</script>

<style scoped>
.column_detalle {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
